import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "thumbnail",
    "distance",
    "coverage",
    "resolution",
    "download",
    "addToCartButton",
    "resolutionWarning",
    "rasterCoverageIdHiddenField",
    "rasterCoverageBoundingBoxHiddenField",
  ];

  static values = {
    data: Array,
  };

  connect() {
    this.update();
  }

  update() {
    const distance = parseFloat(this.distanceTarget.value);
    const coverage = parseInt(this.coverageTarget.value);

    // find the data object that matches the distance and coverage values
    const dataObject = this.dataValue.find(
      (item) => item.distance === distance && item.id === coverage,
    );

    // get dataObject.preview_url via fetch and update the src of the preview image with the resulting JSON's `image` key, which is a base64 encoded image
    fetch(`${dataObject.preview_url}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        console.log(this.dataValue);

        let dpi = Math.round(data.width / 9.0);
        this.resolutionTarget.innerHTML = `${data.width}&times;${data.height} (${dpi} dpi)`;
        this.thumbnailTarget.src = data.image;
        this.thumbnailTarget.classList.remove("invisible");
        this.rasterCoverageIdHiddenFieldTarget.value = dataObject.id;
        this.rasterCoverageBoundingBoxHiddenFieldTarget.value = dataObject.bbox;

        if (dpi < 200) {
          this.addToCartButtonTarget.classList.add("disabled");
          this.addToCartButtonTarget.disabled = true;
          this.resolutionWarningTarget.classList.remove("invisible");
        } else {
          this.addToCartButtonTarget.classList.remove("disabled");
          this.addToCartButtonTarget.disabled = false;
          this.resolutionWarningTarget.classList.add("invisible");
        }

        // update the download link, if download_url is present
        if (dataObject.download_url) {
          this.downloadTarget.href = dataObject.download_url;
        }
      });
  }
}
