import React, { useState, useRef, useEffect } from "react";
import geojsonExtent from "@mapbox/geojson-extent";
import useMobileDetect from "use-mobile-detect-hook";
import Cookies from "js-cookie";
import { debounce } from "lodash";

import maplibregl from "!maplibre-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "maplibre-gl/dist/maplibre-gl.css";

import BaseSearchBox from "./BaseSearchBox";
import LayerToggle from "./maps/LayerToggle";
import Legend from "./maps/Legend";
import RasterCoverageSelector from "./maps/RasterCoverageSelector";

import useCoverageSelect from "../shared/useCoverageSelect";
import { loadCoverages } from "./maps/layers/coverages";

import {
  ramp as countiesRamp,
  source as countiesSource,
  layer as countiesLayer,
} from "./maps/layers/counties";

import {
  ramp as coverageRamp,
  source as coverageSource,
  layer as coverageLayer,
} from "./maps/layers/coverage";

import {
  source as countySource,
  layer as countyLayer,
} from "./maps/layers/county";

const CountyMap = ({
  countyGeoJSON,
  searchPath,
  unmappedPath,
  unmappedRollsCount,
  coverages,
}) => {
  const ZOOM_SEARCH_ENABLE = 10;
  const HID_COUNTY_MAP_TIP_COOKIE_NAME = "hid_county_map_tip";

  const detectMobile = useMobileDetect();

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [hideInstructions, setHideInstructions] = useState(
    Cookies.get(HID_COUNTY_MAP_TIP_COOKIE_NAME) === "true"
  );
  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [loading, setLoading] = useState(true);

  const [displayLng, setDisplayLng] = useState(null);
  const [displayLat, setDisplayLat] = useState(null);
  const [displayZoom, setDisplayZoom] = useState(null);
  const [displayQ, setDisplayQ] = useState(null);
  const [imagesCount, setImagesCount] = useState(null);

  const [coverageActive, setCoverageActive] = useState(null);
  const handleCoverageClick = () => setCoverageActive(!coverageActive);

  const [satelliteActive, setSatelliteActive] = useState(null);
  const handleSatelliteClick = () => {
    setSatelliteActive(!satelliteActive);
  };

  const [activeCoverage, setActiveCoverage] = useState(null);
  const handleSatelliteSelect = (coverage) => {
    setActiveCoverage(coverage);
  };

  const searchCenter = async () => {
    if (!map.current) return;

    const center = map.current.getCenter();
    const zoom = map.current.getZoom();

    setDisplayLng(center.lng.toFixed(4));
    setDisplayLat(center.lat.toFixed(4));
    setDisplayQ(null);
    setDisplayZoom(zoom.toFixed(2));

    if (zoom < ZOOM_SEARCH_ENABLE) return;

    const pointInView = await map.current.project(center);

    const features = map.current.queryRenderedFeatures(pointInView, {
      layers: ["coverage"],
    });

    const totalCount = features.reduce((sum, i) => {
      return sum + i.properties["count"];
    }, 0);

    setImagesCount(totalCount);
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: "/map/style.json?streets=v2",
      bounds: geojsonExtent(countyGeoJSON),
      fitBoundsOptions: {
        padding: { top: 75, bottom: 15, left: 15, right: 15 },
      },
      trackResize: false,
    });

    document.addEventListener("webkitfullscreenchange", () => {
      setTimeout(() => map.current.resize(), 50);
    });

    document.addEventListener("fullscreenchange", () => {
      setTimeout(() => map.current.resize(), 50);
    });

    if (!detectMobile.isMobile()) {
      map.current.addControl(new maplibregl.FullscreenControl(), "top-left");

      map.current.addControl(
        new maplibregl.NavigationControl({ showCompass: false }),
        "top-left"
      );
    }

    map.current.addControl(
      new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      "top-left"
    );

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    map.current.on("load", () => {
      loadCoverages(map, coverages);

      map.current.addSource("coverage", coverageSource);

      map.current.addLayer(countiesLayer);
      map.current.addLayer(coverageLayer);

      map.current.addSource("county", countySource(countyGeoJSON));
      map.current.addLayer(countyLayer);
    });
  });

  useCoverageSelect(map, satelliteActive, activeCoverage, setActiveCoverage);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("sourcedata", function (e) {
      setLoading(true);

      if (e.isSourceLoaded) {
        setLoading(false);
      }
    });
  }, [map]);

  const debouncedSearch = useRef(
    debounce(async () => {
      await searchCenter();
    }, 150)
  ).current;

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize

    map.current.on("move", async () => {
      debouncedSearch();
    });
  }, [map]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    if (coverageActive === null) return;

    ["coverage", "counties"].forEach((layer) => {
      map.current.setPaintProperty(
        layer,
        "fill-opacity",
        coverageActive ? 0.5 : 0.01
      );
    });
  }, [coverageActive]);

  useEffect(() => {
    if (!map.current) return;
    if (!lng || !lat) return;

    map.current.jumpTo({ center: [lng, lat], zoom: 16 });
  }, [lng, lat]);

  return (
    <div id="map-wrapper">
      <div className="search-wrapper">
        <BaseSearchBox
          placeholder="Find an address"
          onSelect={(item) => {
            if (item && item.data) {
              fetch(item.data)
                .then((response) => response.json())
                .then((data) => {
                  setLat(data.lat);
                  setLng(data.lng);
                  setDisplayQ(data.address);
                });
            }
          }}
          types={"addresses"}
        />
      </div>

      <div ref={mapContainer} className="map-container">
        <div className="maplibregl-ctrl-group absolute z-50 top-[10px] right-[10px] px-2 py-1 bg-white space-x-3 flex flex-row items-center">
          {coverages ? (
            <RasterCoverageSelector
              coverages={coverages}
              checked={satelliteActive}
              onChange={handleSatelliteClick}
              onSelect={handleSatelliteSelect}
            />
          ) : null}

          <LayerToggle checked={coverageActive} onChange={handleCoverageClick}>
            Coverage
          </LayerToggle>
        </div>

        {displayZoom < ZOOM_SEARCH_ENABLE && !hideInstructions && (
          <div>
            <div
              className="shade"
              onClick={() => setHideInstructions(true)}
            ></div>
            <div className="instructions">
              <div
                onClick={() => {
                  Cookies.set(HID_COUNTY_MAP_TIP_COOKIE_NAME, "true", {
                    expires: 30,
                  });
                  setHideInstructions(true);
                }}
              >
                <svg
                  className="close"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z" />
                </svg>
              </div>
              <svg
                className="arrow-up"
                width="100"
                height="111"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="arrow-up arrow"
                  d="M46.125 19.619c-8.279 17.648-20.822 46.867-16.468 66.583 4.895 22.151 24.899 12.535 41.708 7.549"
                  stroke="#56B3D2"
                  strokeWidth="2.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  className="arrow-up tail"
                  d="M26.826 22.036C37.716 20.37 43.453 11.102 53.361 7.49c-.631 8.043-1.18 24.408 2.945 31.81"
                  stroke="#56B3D2"
                  strokeWidth="2.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <svg
                className="no-mobile arrow-coverage"
                width="276"
                height="209"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="arrow-coverage arrow"
                  d="M2 201.027c53.67 21.379 129.983-10.887 143.448-70.35 3.53-15.6 8.833-46.238-8.264-55.935-37.504-21.266-54.947 76.974 6.21 64.059 25.292-5.344 45.891-29.029 62.082-47.734 19.228-22.201 35.754-46.59 54.983-68.788"
                  stroke="#56B3D2"
                  strokeWidth="2.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  className="arrow-coverage tail"
                  d="M273.931 49.04c-6.627-15.839 1.007-30.934-2.249-47.04-10.153 7.317-31.416 21.226-44.572 21.696"
                  stroke="#56B3D2"
                  strokeWidth="2.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="heading">Know the current address?</div>
              <div className="subheading">
                Begin by typing it above, or zoom and move the map to search an
                area.
              </div>
              <div className="tip">
                <strong>Tip:</strong> Click Satellite to view current imagery or
                Coverage to view an overlay of our coverage.
              </div>
            </div>
          </div>
        )}

        {displayZoom >= ZOOM_SEARCH_ENABLE && <div className="crosshair" />}

        {coverageActive && (
          <Legend ramp={displayZoom < 7 ? countiesRamp : coverageRamp} />
        )}

        <div
          className="lat-lng"
          style={{ color: satelliteActive ? "white" : "inherit" }}
        >
          {displayLat}, {displayLng}
        </div>

        <div id="results">
          {displayZoom >= ZOOM_SEARCH_ENABLE && imagesCount > 0 && (
            <form action={searchPath} method="get">
              <input type="hidden" name="type" value="address" />
              <input type="hidden" name="lat" value={displayLat || ""} />
              <input type="hidden" name="lng" value={displayLng || ""} />
              <input type="hidden" name="q" value={displayQ || ""} />

              <button disabled={!imagesCount > 0}>Show Nearby Photos</button>
            </form>
          )}

          <div
            className="unmapped"
            style={{
              display:
                loading === false &&
                imagesCount == 0 &&
                displayZoom >= ZOOM_SEARCH_ENABLE
                  ? "block"
                  : "none",
            }}
          >
            <div className="message">
              We aren't finding any photos in this location.
            </div>

            {unmappedRollsCount > 0 && (
              <div className="other-rolls">
                However, there are {unmappedRollsCount} rolls of unmapped film
                that may contain the photo you are looking for!
              </div>
            )}

            {unmappedRollsCount === 0 && (
              <div className="no-unmapped-rolls">
                However, there may be photos in other areas in this county.
                {coverageActive === false && (
                  <a onClick={() => setCoverageActive(true)}>Show Coverage</a>
                )}
              </div>
            )}

            <div className="button browse-unmapped-film">
              <a href={unmappedPath}>Browse Unmapped Film</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import renderer from "../shared/renderer";
renderer(CountyMap);
