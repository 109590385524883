import React from "react";
import clsx from "clsx";

import SearchBox from "./SearchBox";
import PLSSSelector from "./PLSSSelector";
import CountySelector from "./CountySelector";

function SearchCategoryIndicator({
  selected,
  setSelected,
  categoryId,
  children,
}) {
  return (
    <div
      onClick={() => {
        setSelected(categoryId);
        _vaTrack("search.tab", { event_value: categoryId });
      }}
      className={clsx(
        "cursor-pointer",
        selected == categoryId &&
          "font-bold underline decoration-va-blue decoration-2 underline-offset-4"
      )}
    >
      {children}
    </div>
  );
}

export default function Search() {
  const [selected, setSelected] = React.useState("address");

  return (
    <div>
      <div className="text-sm">
        <div className="flex flex-col sm:flex-row">
          <div className="font-bold sm:mr-2">Search by:</div>
          <div className="flex space-x-3 mb-3">
            <SearchCategoryIndicator
              selected={selected}
              setSelected={setSelected}
              categoryId="address"
            >
              Address
            </SearchCategoryIndicator>
            <a className="hidden sm:block" href="/photos">
              Map
            </a>
            <SearchCategoryIndicator
              selected={selected}
              setSelected={setSelected}
              categoryId="keyword"
            >
              Keyword
            </SearchCategoryIndicator>
            <SearchCategoryIndicator
              selected={selected}
              setSelected={setSelected}
              categoryId="county"
            >
              State &amp; County
            </SearchCategoryIndicator>
            <SearchCategoryIndicator
              selected={selected}
              setSelected={setSelected}
              categoryId="plss"
            >
              PLSS{" "}
              <span className="hidden md:inline font-normal text-gray-500">
                (Township, Range, Section)
              </span>
            </SearchCategoryIndicator>
            <SearchCategoryIndicator
              selected={selected}
              setSelected={setSelected}
              categoryId="photo_code"
            >
              Photo Code
            </SearchCategoryIndicator>
          </div>
        </div>
      </div>
      <div className="h-[60px]">
        {selected === "county" && <CountySelector />}

        {selected === "keyword" && (
          <SearchBox placeholder="Enter keyword (e.g., elevator, smith farm)" />
        )}
        {selected === "address" && (
          <SearchBox placeholder="Enter address (e.g., 123 Co. Rd. 644)" />
        )}
        {selected === "photo_code" && (
          <SearchBox placeholder="Enter photo code (e.g., 1-ABC-23)" />
        )}
        {selected === "plss" && <PLSSSelector />}
      </div>
    </div>
  );
}

import renderer from "../shared/renderer";
renderer(Search);
